<template>
  <div class="selfcare_redirect__wrapper">
    <div class="selfcare_redirect__container">
      <div class="selfcare_redirect__content">
        <h2 class="title selfcare_redirect__title">
          Sie sind schon Kunde bei {{ $brand.name }} und wollen …
        </h2>
        <p class="selfcare_redirect__text">
          Ihren Tarif wechseln? Loggen Sie sich einfach unter "Mein
          {{ $brand.name }}" ein und wir zeigen Ihnen wie das geht.
        </p>
      </div>
      <div class="selfcare_redirect__button">
        <ButtonComponent href="/selfcare"
          >In Mein {{ $brand.name }} einloggen</ButtonComponent
        >
      </div>
    </div>
    <img alt="" class="selfcare_redirect_image" :src="RedirectImage" />
  </div>
</template>

<script>
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import RedirectImage from './resources/tariff_switch-${BRAND_CODE}.webp'

export default {
  name: 'PortalSelfcareRedirect',
  components: {
    ButtonComponent
  },
  data() {
    return {
      RedirectImage
    }
  }
}
</script>

<style scoped lang="scss">
.selfcare_redirect__wrapper {
  @include breakpoint($from-tablet) {
    display: flex;
    flex-direction: row-reverse;
  }
}

.selfcare_redirect__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @include breakpoint($from-tablet) {
    justify-content: center;
    align-items: start;
  }
}

.selfcare_redirect__title {
  margin-bottom: 0.25rem;
  font-size: calc-rem-min(22px, 40px);
  line-height: normal;

  @include breakpoint($from-tablet) {
    font-size: calc-rem-min(26px, 40px);
  }
}

.selfcare_redirect__text {
  @include font_normal;

  margin: 0;
}

.selfcare_redirect__button {
  display: flex;
  justify-content: center;
  max-width: 90%;
  margin-top: 40px;

  @include breakpoint($up-to-tablet) {
    width: 100%;
  }

  > .button-component__highlight {
    align-self: center;

    @include breakpoint($from-desktop) {
      margin-right: 8px;
      font-size: calc-rem(20px);
    }
  }
}

.selfcare_redirect_image {
  margin-top: 32px;

  @include breakpoint($from-tablet) {
    margin-top: 0;
  }
}
</style>
